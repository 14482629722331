var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"vx-row mt-3 mb-6 flex justify-between",staticStyle:{"min-height":"3rem"}},[_c('div',{staticClass:"vx-col w-full sm:w-1/2 flex justify-center sm:justify-between"},[_c('h2',{staticClass:"title-sinao self-center"},[_vm._v(" "+_vm._s(_vm.$t("Mes entreprises"))+" ")])]),(!_vm.errorAppsMessage)?_c('div',{staticClass:"w-fit vx-col flex justify-center sm:justify-end"},[_c('vs-tooltip',{attrs:{"text":_vm.$t('Tooltip ajouter une entreprise'),"position":"top"}},[_c('s-button',{staticClass:"sm:m-base float-none sm:float-right",attrs:{"icon":"icon-plus","type":"border","disabled":_vm.loading},on:{"click":_vm.showNewAppForm}},[_vm._v(" "+_vm._s(_vm.$t("Ajouter une entreprise"))+" ")])],1)],1):_vm._e()]),_c('transition',{attrs:{"name":"pop-appear"}},[(_vm.newAppForm)?_c('vs-card',{staticClass:"pop shadow-none"},[_c('div',{staticClass:"vx-row mt-3"},[_c('div',{staticClass:"vx-col w-full sm:w-1/2 m-auto"},[_c('div',{staticClass:"w-full flex m-auto text-center"},[_c('div',{staticClass:"vx-col w-full m-auto text-center align-vertical w-full"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Ajout d’une nouvelle entreprise")))]),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
										required: true,
										min: 4,
										regex: new RegExp('^[A-zÀ-ÿ0-9 -]+$', 'ui')
									}),expression:"{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmin: 4,\n\t\t\t\t\t\t\t\t\t\tregex: new RegExp('^[A-zÀ-ÿ0-9 -]+$', 'ui')\n\t\t\t\t\t\t\t\t\t}"}],ref:"appName",staticClass:"m-auto mt-5",attrs:{"name":"appName","placeholder":_vm.$t('Nom de l’entreprise'),"color":"var(--primary)","data-vv-validate-on":"blur","label-placeholder":"Nom de l’entreprise","left":""},model:{value:(_vm.newAppName),callback:function ($$v) {_vm.newAppName=$$v},expression:"newAppName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('appName')),expression:"errors.has('appName')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("appName"))+" "),_c('br')]),_c('br'),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sizeCompany),expression:"sizeCompany"}],staticClass:"vs-input select-input",attrs:{"id":"","name":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sizeCompany=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v(" "+_vm._s(_vm.$t("Choisir votre taille d’entreprise"))+" ")]),_vm._l((_vm.sizes),function(size){return _c('option',{key:size.value,domProps:{"value":size.value}},[_vm._v(" "+_vm._s(size.text)+" ")])})],2),_c('div',{staticClass:"mt-5"},[_c('s-button',{staticClass:"ml-3",attrs:{"type":"border"},on:{"click":_vm.toggleCards}},[_vm._v(" "+_vm._s(_vm.$t("Annuler"))+" ")]),_c('s-button',{ref:"submit",staticClass:"ml-3 vs-con-loading__container",attrs:{"disabled":!(
												_vm.newAppName.length >= 4 &&
												['solo', 'small', 'medium'].includes(_vm.sizeCompany)
											) || _vm.disabled},on:{"click":_vm.createApp}},[_vm._v(" "+_vm._s(_vm.$t("Valider"))+" ")])],1)],1)])])])]):_vm._e()],1),(_vm.apps && _vm.apps.length >= 8)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"vs-input mt-5 search-input",class:{ veiled: _vm.disableCards },attrs:{"type":"text","placeholder":"Rechercher une entreprise"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}):_vm._e(),_c('div',{staticClass:"veilable",class:{ veiled: _vm.disableCards }},[(_vm.apps && _vm.apps.length === 0)?_c('vs-card',{staticClass:"text-center veilable"},[_c('h4',{staticStyle:{"padding-bottom":"30px"}},[_vm._v(_vm._s(_vm.$t("Vous ne disposez encore d’aucune entreprise")))]),_c('h5',{staticClass:"mt-3",domProps:{"innerHTML":_vm._s(_vm.$t('Cliquez sur ...'))}}),_c('p',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("Si vous ne retrouvez pas vos données, veuillez recharger la page.")))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],ref:"loader",staticClass:"centerx vs-con-loading__container",staticStyle:{"height":"100px"}},[_vm._v(" "+_vm._s(_vm.$t("Chargement..."))+" ")]),_vm._l((_vm.filteredApps),function(app){return [(_vm.apps.length < 8)?_c('OrganizationItem',{key:app.id,staticClass:"w-full",attrs:{"app":app,"disabled":_vm.disableCards}}):_vm._e(),(_vm.apps.length >= 8)?_c('OrganizationItemSmall',{key:app.id,staticClass:"w-full",attrs:{"app":app,"disabled":_vm.disableCards}}):_vm._e()]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }